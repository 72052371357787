<template>
  <b-card
    v-if="company"
    class="card-app-design"
  >
    <!-- <b-badge variant="light-primary">{{member.accredation_date}}</b-badge> -->
    <div class="design-group">
      <h6 class="section-label">
        الإسم
      </h6>
      <b-card-title class="mt-1 mb-75">
        {{ company.name }}
      </b-card-title>
    </div>

    <!-- design group -->
    <div class="design-group">
      <h6 class="section-label">
        البريد الالكتروني
      </h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
      >
        {{ company.email }}
      </b-badge>
    </div>
    <div class="design-group">
      <h6 class="section-label">
        رقم الهاتف
      </h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
      >
        {{ company.telephone }}
      </b-badge>
    </div>
    <div
      v-for="(m, index) in company.accredited_members"
      :key="m.name"
      class="employee-task d-flex justify-content-between align-items-center"
    >
      <b-media no-body>
        <b-media-aside class="mr-75 float-left">
          <b-form-radio
            name="member"
            class="mb-0"
            @change="onMemberChecked(index)"
          />
        </b-media-aside>
        <b-media-body class="my-auto float-right">
          <h6 class="mb-0">
            {{ m.name }}
          </h6>
          <small>{{ m.email }}</small>
        </b-media-body>
      </b-media>
    </div>
    <!--/ design group -->

    <div class="design-planning-wrapper">
      <div class="design-planning">
        <p class="card-text mb-25">
          المنطقة
        </p>
        <h6 class="mb-0">
          {{ company.region }}
        </h6>
      </div>
      <div class="design-planning">
        <p class="card-text mb-25">
          فرع التقييم
        </p>
        <h6 class="mb-0">
          {{ company.sector }}
        </h6>
      </div>
    </div>

    <!-- button -->
    <b-button
      v-if="member && member.accredited == 'Yes' && validateEmail(member.email)"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      class="mr-1"
      @click="onMemberSignup()"
    >
      أكمل التسجيل بالبيانات المتاحة
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :disabled="!member"
      @click="onManualSignup()"
    >
      عدّل في بيانات التسجيل
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      member: null,
      member_id: null,
      company: null,
    };
  },
  mounted() {
    this.$root.$on('searchLicenseNumber', async (license_number) => {
      const response = await this.$http.post('/api/license/company/verify', {
        membership_number: license_number,
      });
      const { data } = response;
      if (data.status == 'success') {
        this.company = data.data;
      } else {
        this.company = null;
        this.$root.$emit('showMessage', {
          type: 'danger',
          message: response.data.message,
        });
      }
      this.$root.$emit('searchLicenseNumberCompleted', response.data);
      console.log('CompanySummaryCard', response.data);
    });
  },
  beforeDestroy() {
    this.$root.$off('searchLicenseNumber');
  },
  methods: {
    onMemberChecked(index) {
      this.member = this.company.accredited_members[index];
    },
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    onMemberSignup() {
      this.$root.$emit('onMemberSignup', {
        member_id: this.member.id,
        email: this.member.email,
        membership_number: this.company.membership_number,
      });
    },
    onManualSignup() {
      this.$root.$emit('onManualSignup', {
        ...this.member,
        company: this.company,
      });
    },
  },
};
</script>
