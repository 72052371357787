<template>
  <b-row>
    <b-col md="10">
      <b-form-group
        :label="label"
        label-for="license_number"
        :description="description"
      >
        <input
          id="license_number"
          v-model="license_number"
          type="text"
          class="form-control"
          placeholder="رقم العضوية"
        >
        <b-form-text id="input-live-help">
          ضغطك على زر "البحث" إقرار بالسماح لنا استعمال رقم العضوية لجلب  بيانات خاصة بك
        </b-form-text>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <div class="form-group">
        <b-button
          variant="primary"
          class="mt-0 mt-md-2"
          :disabled="license_number.length < 1"
          @click.prevent="searchLicenseNumber"
        >
          بحث
          <b-spinner
            v-if="isLoading"
            small
          />
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BFormText, BFormGroup, BSpinner,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BSpinner,
    BFormText,
  },
  props: {
    label: {
      type: String,
      default: 'رقم العضوية',
    },
    description: {
      type: String,
      default: 'أدخل رقم العضوية المسند إليك من قبل هيئة التقييم العقاري',
    },
  },
  data() {
    return {
      isLoading: false,
      license_number: '',
    };
  },
  mounted() {
    this.$root.$on('searchLicenseNumberCompleted', async (data) => {
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    this.$root.$off('searchLicenseNumberCompleted');
  },
  methods: {
    searchLicenseNumber() {
      this.isLoading = true;
      this.$root.$emit('searchLicenseNumber', this.license_number);
    },
  },
};
</script>

<style>
</style>
