<template>
  <b-card
    v-if="member"
    class="card-app-design"
  >
    <!-- <b-badge variant="light-primary">{{member.accredation_date}}</b-badge> -->
    <div class="design-group">
      <h6 class="section-label">
        الإسم
      </h6>
      <b-card-title class="mt-1 mb-75">
        {{ member.name }}
      </b-card-title>
    </div>

    <!-- design group -->
    <div class="design-group">
      <h6 class="section-label">
        البريد الالكتروني
      </h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
      >
        {{ member.email }}
      </b-badge>
    </div>
    <div class="design-group">
      <h6 class="section-label">
        رقم الهاتف
      </h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
      >
        {{ member.telephone }}
      </b-badge>
    </div>
    <!--/ design group -->

    <div class="design-planning-wrapper">
      <div class="design-planning">
        <p class="card-text mb-25">
          المنطقة
        </p>
        <h6 class="mb-0">
          {{ member.region }}
        </h6>
      </div>
      <div class="design-planning">
        <p class="card-text mb-25">
          فرع التقييم
        </p>
        <h6 class="mb-0">
          {{ member.sector }}
        </h6>
      </div>
      <div class="design-planning">
        <p class="card-text mb-25">
          لديه ترخيص؟
        </p>
        <h6 class="mb-0">
          {{ member.accredited == "Yes" ? "نعم" : "لا" }}
        </h6>
      </div>
    </div>

    <!-- button -->
    <b-button
      v-if="member.accredited == 'Yes' && validateEmail(member.email)"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      class="mr-1"
      @click="onMemberSignup()"
    >
      أكمل التسجيل بالبيانات المتاحة
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="onManualSignup()"
    >
      عدّل في بيانات التسجيل
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      member: null,
    };
  },
  mounted() {
    this.$root.$on('searchLicenseNumber', async (license_number) => {
      const response = await this.$http.post('/api/license/member/verify', {
        license_number,
      });
      const { data } = response;
      if (data.status == 'success') {
        this.member = data.data;
      } else {
        this.member = null;
        this.$root.$emit('showMessage', {
          type: 'danger',
          message: response.data.message,
        });
      }
      this.$root.$emit('searchLicenseNumberCompleted', response.data);
      console.log('MemberSummaryCard', response.data);
    });
  },
  beforeDestroy() {
    this.$root.$off('searchLicenseNumber');
  },
  methods: {
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    onMemberSignup() {
      this.$root.$emit('onMemberSignup', { member_id: this.member.id, email: this.member.email, license_number: this.member.membership_number });
    },
    onManualSignup() {
      this.$root.$emit('onManualSignup', { ...this.member });
    },
  },
};
</script>
