<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img
          fluid
          :src="logoUrl"
          alt="Muqaym"
          style="height: 50px; width: auto"
        />
        <h2 class="brand-text text-primary ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{
          backgroundImage: `url(' ${imgUrl}')`,
          backgroundSize: 'cover',
        }"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            المغامرة تبدأ هنا 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            التكلفة لا تقارن بما تقدمه منصة مقيّم من مزايا، ابدأ بالتجربة ولن
            تندم!
          </b-card-text>
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
          <!-- form -->
          <div v-if="!showRegularRegisterForm">
            <license-number-form
              :label="user.account_type == 'personal'?'رقم العضوية':'رقم العضوية الشركة'"
              :description="user.account_type == 'personal'?
                'أدخل رقم العضوية المسند إليك من قبل هيئة التقييم العقاري'
                :'أدخل رقم العضوية المسند إلى شركتك من قبل هيئة التقييم العقاري'"
            />
            <company-summary-card v-if="user.account_type != 'personal'" />
            <member-summary-card v-if="user.account_type == 'personal'" />
          </div>
          <validation-observer
            v-if="showRegularRegisterForm"
            ref="registerForm"
          >
            <b-form class="auth-register-form mt-2">
              <b-row>
                <b-col md="6">
                  <!-- name -->
                  <b-form-group
                    label="الاسم الثلاثي"
                    label-for="register-name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="name"
                      name="name"
                    >
                      <b-form-input
                        id="register-name"
                        v-model="user.name"
                        name="register-name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="عبد الله المحمد"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- email -->
                  <b-form-group
                    label="البريد الالكتروني"
                    label-for="email"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="email"
                      name="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="user.email"
                        name="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="mohamed@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- mobile -->
                  <b-form-group
                    label="رقم الهاتف"
                    label-for="register-mobile"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="mobile"
                      name="mobile"
                    >
                      <b-form-input
                        id="register-mobile"
                        v-model="user.mobile"
                        name="register-mobile"
                        :state="errors.length > 0 ? false : null"
                        placeholder="+966555555555"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- license_number -->
                  <b-form-group
                    label="رقم العضوية"
                    label-for="register-license_number"
                    description="رقم العضوية المسندة من قبل هيئة التقييم العقاري"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="license_number"
                      name="license_number"
                    >
                      <b-form-input
                        id="register-license_number"
                        v-model="user.license_number"
                        name="license_number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="123456789"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- password -->
                  <b-form-group
                    label-for="password"
                    label="كلمة المرور"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="password"
                      name="password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="user.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  vid="g_recaptcha_response"
                  name="g_recaptcha_response"
                >
                  <vue-recaptcha
                    ref="recaptcha"
                    language="ar"
                    sitekey="6LeSbuoeAAAAAE77biaI1KgMiGa6Hx8M6Ik0EuJH"
                    @verify="verifyRecaptcha"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="user.accept_terms"
                  value="true"
                  name="checkbox-1"
                >
                  أوافق على
                  <b-link>سياسة الخصوصية والشروط</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                :disabled="user.accept_terms === false"
                type="submit"
                @click.prevent="validationForm"
              >
                طلب التسجيل
              </b-button>
            </b-form>
          </validation-observer>
          <p class="small">
            <span>{{ toggleRegisterText }}</span>
            <b-link @click="toggleRegisterForm()">
              <span>&nbsp;{{ toggleRegisterLinkText }}</span>
            </b-link>
          </p>
          <p class="text-center mt-2">
            <span>هل لديك حساب؟</span>
            <b-link to="login">
              <span>&nbsp;تسجيل الدخول</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAlert,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VueRecaptcha } from 'vue-recaptcha';
import CheckboxCardVertical from '@core/components/cards/CheckboxCardVertical.vue';
import LicenseNumberForm from './LicenseNumberForm.vue';
import CompanySummaryCard from './CompanySummaryCard.vue';
import MemberSummaryCard from './MemberSummaryCard.vue';

export default {
  components: {
    LicenseNumberForm,
    CompanySummaryCard,
    MemberSummaryCard,
    CheckboxCardVertical,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BAlert,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility, alertMessageMixin],
  data() {
    return {
      showRegularRegisterForm: false,
      options: [
        { text: 'شخصي', value: 'personal' },
      ],
      user: {
        name: '',
        email: '',
        password: '',
        account_type: 'personal',
        phone: '',
        mobile: '',
        company_name: '',
        commercial_register: '',
        company_license_number: '',
        license_number: '',
        accept_terms: false,
        g_recaptcha_response: '',
      },
      sideImg: require('@/assets/images/stock/900x600/19.jpg'),
      // validation
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      return require('@/assets/images/logo/muqaym-logo.png');
    },
    toggleRegisterText() {
      return this.showRegularRegisterForm
        ? 'تفضل التسجيل برقم العضوية؟'
        : 'تفضل التسجيل يدويا؟';
    },
    toggleRegisterLinkText() {
      return this.showRegularRegisterForm
        ? 'تسجيل حساب برقم العضوية'
        : 'تسجيل حساب يدوي';
    },
  },
  mounted() {
    this.$root.$on('showMessage', (message) => {
      this.displayMessage(message);
    });
    this.$root.$on('searchLicenseNumber', (licenseNumber) => {
      this.resetMessage();
    });
    this.$root.$on('onMemberSignup', async (data) => {
      await this.onMemberSignup(data);
    });
    this.$root.$on('onManualSignup', async (data) => {
      await this.onManualSignup(data);
    });
  },
  beforeDestroy() {
    this.$root.$off([
      'showMessage',
      'searchLicenseNumber',
      'onMemberSignup',
      'onManualSignup',
    ]);
  },
  methods: {
    toggleRegisterForm() {
      this.showRegularRegisterForm = !this.showRegularRegisterForm;
    },
    verifyRecaptcha(response) {
      this.user.g_recaptcha_response = response;
    },
    async validationForm() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await this.$http.post('/api/register/enterprise', {
              ...this.user,
              password_confirmation: this.user.password,
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم التسجيل بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            this.showMessage = true;
            this.message = response.data.message;
          } catch (error) {
            this.$refs.registerForm.setErrors(error.response.data.errors);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                text: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
        }
      });
    },
    async onMemberSignup(data) {
      try {
        const response = await this.$http.post(
          '/api/register/accredited-member',
          {
            ...data,
            account_type: this.user.account_type,
          },
        );

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'تم التسجيل بنجاح',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
        this.displayMessage({ message: response.data.message, type: 'success' });
      } catch (error) {
        const { message, errors } = error.response.data;
        if (errors) {
          const errorMessage = {
            message: '',
            type: 'danger',
          };
          Object.values(errors).forEach((error) => {
            errorMessage.message += `${error[0]}<br>`;
          });
          this.displayMessage(errorMessage);
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            text: message,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        });
      }
    },
    onManualSignup(data) {
      this.user.name = data.name;
      this.user.email = data.email;
      this.user.phone = data.company ? data.company.telephone : '';
      this.user.mobile = data.mobile;
      this.user.company_name = data.company ? data.company.name : '';
      this.user.license_number = data.membership_number;
      this.user.company_license_number = data.company
        ? data.company.membership_number
        : '';

      this.showRegularRegisterForm = true;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
